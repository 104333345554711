<template>
  <div>
    <Header></Header>
    <div class="cpal1">
      <img class="cpalA1" src="@/assets/cpal.png" alt="" />
      <div class="cpalB1">产品案例 - {{ productCase.title }}</div>
      <div class="cpalC1">{{ productCase.eTitle }}</div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">{{ productCase.title }}</div>
        <div class="bodyTitle">
          <div class="product">{{ productCase.eTitle }}</div>
          <!-- <div class="more">
            <div class="datetime">2022-10-23</div>
          </div> -->
        </div>
      </div>
      <div class="bodyContent">
        <div style="width: 1200px;margin-left: 30px" v-html="productCase.content.replaceAll('/dev-api',baseURL).replaceAll('/prod-api',baseURL)" class="ql-editor" >
        </div>
      </div>
      <div class="LastNext">
        <div v-if="nextMap.previous!==null">
          <div class="last"  iconClass="" @click="oneL">上一案例：{{ nextMap.previous.title }}</div>
        </div>
        <div v-if="nextMap.previous===null">
          <div class="last"  iconClass="" @click="oneL"></div>
        </div>

        <div class="next" v-if="nextMap.next!==null" @click="oneN">下一案例：{{ nextMap.next.title }}</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
import {getNext, getProductCase} from "../../api/open";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      last: "",
      next: "智慧煤矿",
      baseURL: process.env.VUE_APP_BASE_API,
      id:null,
      productCase:{},
      nextMap:{}
    };
  },
  watch: {
    '$route' (to, from) { //监听URL地址栏参数变化
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.getById()
        this.toNext();
      }
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.getById()
    this.toNext();
  },
  methods: {
    getById(){
      getProductCase({id:this.id}).then(res=>{
        this.productCase = res.data
      })
    },
    toNext(){
      getNext({id:this.id}).then(res=>{
        this.nextMap = res.data
      })
    },
    oneL() {
      this.$router.push({path:"productCase1",query:{id:this.nextMap.previous.id} });
    },
    oneN() {
      this.$router.push({path:"productCase1",query:{id:this.nextMap.next.id} });
    }
  },
};
</script>

<style scoped>
/deep/ .ql-size-large{
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
}
/deep/ img{
  width: 100%;
}
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.datetime {
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.bodyContent {
  display: block;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 40px;
}
.cpalb1 {
  width: 100%;
  margin: 24px 32px;
}
.fontA1 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 0 32px;
  line-height: 40px;
}
.fontA11 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 46px 32px;
  line-height: 40px;
}
.LastNext {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.last {
    cursor: pointer;
}
.next {
    cursor: pointer;
}
.cpal1 {
  position: relative;
}
.cpalA1 {
  width: 100%;
}
.cpalB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.cpalC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
</style>
